import { isPlatformBrowser } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_ID, Inject, NgModule, PLATFORM_ID } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AngularFireModule } from '@angular/fire/compat';
import { RouterModule } from '@angular/router';
import {
  APP_INIT_TIMESTAMP,
  BrowserStorage,
  EnvironmentInterceptor,
  ErrorsInterceptor,
  FirebaseModule,
  STORAGE,
  TranslateBrowserModule,
  UserService,
} from '../shared';
import initLanguageProvider from '../shared/translate/providers/init-language';
import { LanguagesDropdownModule } from 'src/shared/components/languages-dropdown/languages-dropdown.module';
import { TranslationRowModule } from './modules/translation-row/translation-row.module';
import { SSOInterceptor } from 'src/shared/interceptors/sso.interceptor';
import { AppApiService } from './core/services/app-api.service';
import { LottieComponent, provideLottieOptions } from 'ngx-lottie';
import player from 'lottie-web';
import { MAT_COLOR_FORMATS, NGX_MAT_COLOR_FORMATS } from '../shared/components/color-picker/services';
import { AngularSvgIconModule } from 'angular-svg-icon';

@NgModule({
  imports: [
    BrowserModule.withServerTransition({ appId: 'favie-tech-angular' }),
    TranslateBrowserModule,
    FormsModule,
    RouterModule,
    AppRoutingModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebase),
    FirebaseModule,
    BrowserAnimationsModule,
    LanguagesDropdownModule,
    TranslationRowModule,
    LottieComponent,
    AngularSvgIconModule.forRoot()
  ],
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  providers: [
    provideLottieOptions({
      player: () => player,
    }),
    { provide: STORAGE, useClass: BrowserStorage },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: EnvironmentInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SSOInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorsInterceptor,
      multi: true,
    },
    {
      provide: APP_INIT_TIMESTAMP,
      useValue: new Date().getTime().toString(),
    },
    UserService,
    initLanguageProvider,
    { provide: MAT_COLOR_FORMATS, useValue: NGX_MAT_COLOR_FORMATS },
    AppApiService,
  ],
})
export class AppModule {
  constructor(@Inject(PLATFORM_ID) platformId: object, @Inject(APP_ID) appId: string) {
    const platform = isPlatformBrowser(platformId) ? 'in the browser' : 'on the server';
    console.log(`Running ${platform} with appId=${appId}`);
    console.log('environment', environment);
  }
}
